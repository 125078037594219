import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const PersonalTrainings = () => {
  let title = "Személyi edzések felnőtteknek"
  let subTitle = "Változatos"
  let places = [
    {
      place: "ABS Sportcentrum - Budaörs",
      date: "előre egyeztetett időpontban",
    },
    {
      place: "Fitnesz stúdió - Herceghalom",
      date: "előre egyeztetett időpontban",
    },
  ]
  let description = (
    <span>
      Ha már megbírkózol a saját súlyoddal, akkor jó erőben vagy. Személyi
      edzéseimen az erő és az állóképesség fejlesztése van fókuszban.
      <b>
        <br />
        Mindkettőt elsősorban a saját testsúlyos edzésekre és az instabilitásra
        építem, amikhez gyakran használok speciális eszközöket is. Ilyenek pl. a
        fit-ball, trx, gumikötél, bosu, trambulin, vagy a gymstick is.
      </b>
      <br />
      Törekszem arra, hogy érdekes és egyszerre több izomcsoportot keményen
      megdolgozó gyakorlatokat végezz az irányításommal.
    </span>
  )
  let imageSrc = "personal"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default PersonalTrainings
